import { tns } from '../../../node_modules/tiny-slider/src/tiny-slider';
const $heros = document.querySelectorAll('.act-hero-slider.is-slider');

(function ($) {
    $(document).ready(function () {
        $heros.forEach(function ($h) {
          if ($h.querySelectorAll('.tns-outer').length) {
            $h.tinySlider.destroy();
            return;
          }
          var $id = $h.querySelectorAll('.act-hero-slider__slides')[0].getAttribute('id');
          var organime_slider = tns({
            container: '#'+$id,
            mode:'carousel',
            items: 1,
            "controlsContainer": "#controls",
            "prevButton": '.previous',
            "nextButton": '.next',
            "conrolsPosition": "center",
            autoplay: $h.dataset.tnsAutoplay === '1',
            speed: 700,
            responsive: {
              781: {
                touch: true
              }
            },
            onInit(info) {
              info.controlsContainer.setAttribute('tabindex', -1);
              info.controlsContainer.setAttribute('aria-label', 'Utilisez les flèches pour naviguer le carousel');
              info.nextButton.setAttribute('tabindex', 0);
              info.prevButton.setAttribute('tabindex', 0);

              const slider = document.querySelector('.act-hero-slider');

              slider.querySelectorAll('.tns-slide-cloned a').forEach((el) => {
                el.setAttribute('tabindex', -1);
              });

              slider.addEventListener('focusin', e => {
                const { target } = e;

                var parent = $(target).closest('.act-hero-slider__slide');

                slider.querySelector('.tns-inner').scrollLeft = 0;
                if (typeof parent.attr('id') === "undefined") {
                  return;
                }
                var parentId = parent.attr('id').match(/\d+$/);

                organime_slider.goTo(parentId[0]);
              });
              slider.addEventListener('focusout', e => {
                slider.querySelector('.tns-inner').scrollLeft = 0;
              });
            }
          });
          
          $h.tinySlider = organime_slider;
        });
      })
    })(jQuery);
